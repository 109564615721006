/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React from 'react';
import {
  Row, Col, Jumbotron, Container, Button,
} from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import parseUrl from 'parse-url';
import styled from 'styled-components';
// import Url from 'url-parse';
import Template from './Templates/Dashboard';
//const serverURL = 'https://vo2t3ci7zf.execute-api.us-east-2.amazonaws.com/dev/'
const serverURL = process.env.SERVER_URL || 'https://orbit.thorp.com/node/'

const doFetch = async (path, options, method = 'get', api = '1.1') => {
  const two = (api === '2') ? 2 : '';
  const response = await fetch(`${serverURL}auth/twitter/${method}/${path}`, {
    method: 'POST',
    body: JSON.stringify(options),
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Credentials': true,
    },
  });
  const { status } = response;
  const data = await response.json();
  data.status = status;
  return data;
};


class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      error: null,
      authenticated: false,
      targetURL: '',
      items: [],
    };
  }


  componentDidMount() {
    window.addEventListener('focus', this.gotFocus);
    // Fetch does not send cookies. So you should add credentials: 'include'
    fetch(`${serverURL}auth/login/success`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': true,
      },
    })
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error('failed to authenticate user');
      })
      .then((responseJson) => {
        console.log(responseJson);
        this.setState({
          authenticated: responseJson.success,
          user: responseJson.user,
        });
      })
      .catch((error) => {
        this.setState({
          authenticated: false,
          error: 'Failed to authenticate user',
        });
      });
    const targetURL = localStorage.getItem('url');
    localStorage.removeItem('url');
    if (targetURL) {
      this.setState({ targetURL }, () => this.getMedia());
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener('focus', this.gotFocus);
  }

  fetchTarget = (e) => {
    console.log(e.target.value);
    this.setState({ targetURL: e.target.value });
  };

  gotFocus = () => {
    console.log('got focus', Date());
    navigator.clipboard.readText().then((text) => {
      const parsed = parseUrl(text);
      const { protocol } = parsed;
      console.log(parsed);
      if (protocol === 'https') this.setState({ targetURL: text }, () => this.getMedia());
    });
    // navigator.clipboard.readText().then((text) => {
    //   const { pathname } = Url(text);
    //   const pathParts = pathname.split('/');
    //   const statusId = pathParts[3] || '';
    //   console.log(pathname, pathParts, statusId, statusId.length);
    //   // const client = new Twitter({
    //   //   consumer_key: '5KOrKAXWzcBkasutA2zQQ',
    //   //   consumer_secret: '4jVJfRGvqzITzZac8wJ04NZjnhs44KwsgAyIMiQyc',
    //   //   access_token_key: 'hofjdpidqcwf3un3d4KAHheyIvAYJvhZQSby1mHsK',
    //   //   access_token_secret: '7IZkTEEGoRxAD4CGPLD3q5RQhk8ocmoJ4zDhjEzGA',
    //   // });
    //   // const params = { screen_name: 'nodejs' };
    //   // client.get('statuses/user_timeline', params, (error, tweets, response) => {
    //   //   if (!error) {
    //   //     console.log(tweets);
    //   //   }
    //   // });
    // }).catch((e) => console.log(e));
  }

  getMedia = async () => {
    const { targetURL } = this.state;
    const parsed = parseUrl(targetURL);
    const { pathname, resource } = parsed;

    if (resource.indexOf('instagram.com') !== -1) {
      return this.getMediaInstagram(pathname)
    }

    return this.getMediaTwitter(pathname, targetURL);
  };

  getMediaInstagram = async (pathname) => {
    try {
      const id = pathname.split('/').slice(-1)[0];
      const response = await fetch(`https://www.instagram.com/p/${id}/?__a=1`)
      const data = await response.json();
      const items = []
      console.log(data)
      if (data.graphql.shortcode_media.video_url) {
        items.push({
          type: 'video', url: data.graphql.shortcode_media.video_url,
        });
      }
      if (data.graphql.shortcode_media.edge_sidecar_to_children.edges.length) {
        data.graphql.shortcode_media.edge_sidecar_to_children.edges.forEach(node => {
          console.log(node.node.display_resources)
          const display_resource = node.node.display_resources.splice(-1)
          items.push({
            type: 'photo', url: display_resource[0].src,
          });
        })
      }
      this.setState({ items });
    } catch (error) {
      console.log(error);
    }
  }

  getMediaTwitter = async (pathname, targetURL) => {
    try {
      const id = pathname.split('/').slice(-1)[0];
      //   const id = targetURL.split('/')[5];

      console.log(id);
      const options = {
        id, tweet_mode: 'extended', include_entities: true, include_card_uri: true, include_ext_alt_text: true,
      };
      const response = await doFetch('statuses/lookup', options);
      console.log(response);
      const items = [];
      if (response.status === 200) {
        const tweet = response[0];
        if (tweet.extended_entities) {
          const { media } = tweet.extended_entities;
          media.forEach((asset) => {
            const { type } = asset;
            let url = null;
            if (type === 'video' || type === 'animated_gif') url = asset.video_info.variants.splice(-1)[0].url;
            if (type === 'photo') url = asset.media_url_https;
            if (url) {
              items.push({
                type, url,
              });
            }
          });
        }
        if (tweet.entities) {
          const { urls } = tweet.entities;
          urls.forEach((url) => {
            if (url.display_url.indexOf('twitter.com') === 0) {
              this.setState({ targetURL: url.expanded_url }, () => this.getMedia());
            }
          });
        }
        this.setState({ items });
        console.log(items);
      } else {
        localStorage.setItem('url', targetURL);
        _handleSignInClick();
      }
      return response;
    } catch (error) {
      console.log(error);
    }
    return false;
  }

  render() {
    const { authenticated, items, targetURL } = this.state;
    return (<AboutRender items={items} authenticated={authenticated} fetchTarget={this.fetchTarget} getMedia={this.getMedia} targetURL={targetURL} />);
  }
}


const getBookmarks2 = async () => {
  const options = { count: 100, include_entities: true };
  const tweets = await doFetch('timeline/bookmark.json', options, 'get', '2');
  console.log(tweets);
};


const getBookmarks = async () => {
  let options = { count: 100, include_entities: true };
  const tweets = await doFetch('favorites/list', options, 'post');
  const id = [];
  tweets.map((t) => id.push(t.id_str));
  options = {
    id: id.join(','),
    include_entities: true,
    trim_user: false,
    include_ext_alt_text: true,
    include_card_uri: true,
  };
  const tweets2 = await doFetch('statuses/lookup', options);
  console.log(options, id, tweets2);
};

const _handleSignInClick = () => {
  // Authenticate using via passport api in the backend
  // Open Twitter login page
  // Upon successful login, a cookie session will be stored in the client
  window.open(`${serverURL}auth/twitter`, '_self');
};

const _handleLogoutClick = () => {
  // Logout using Twitter passport api
  // Set authenticated state to false in the HomePage
  window.open(`${serverURL}auth/logout`, '_self');
  this.props.handleNotAuthenticated();
};


const AssetContainer = styled.div`
  text-align: center;
  border: 1px solid black;
  margin: 10%;
  img {
    width: 100%
  }
`

const Photo = (props) => {
  const { href } = props;
  return (<AssetContainer><img alt={href} src={href} /></AssetContainer>);
};

const Video = (props) => {
  const { href } = props;
  // eslint-disable-next-line jsx-a11y/media-has-caption
  return (<AssetContainer><video controls alt={href} src={href} /></AssetContainer>);
};

const Asset = (props) => {
  const { type, href } = props;
  if (type === 'photo') return <Photo href={href} />;
  return <Video href={href} />;
};


const AboutRender = (props) => {
  const {
    authenticated, fetchTarget, getMedia, items, targetURL,
  } = props;
  console.log(props);
  return (
    <Template pageTitle="Hello World" noPadding>
      <React.Fragment>
        <Container>
          <Row className="mb-2 mt-md-5">
            <ul className="menu">
              <li>
                <Link to="/">Home</Link>
              </li>
              {authenticated ? (
                <li onClick={_handleLogoutClick}>Logout</li>
              ) : (
                <li onClick={_handleSignInClick}>Login</li>
              )}
            </ul>
            <Button onClick={() => getBookmarks()}>Get Bookmarks</Button>
            <form>
              <label htmlFor="targetUrl">Target</label>
              {' '}
              <input value={targetURL} name="targetURL" type="text" onChange={fetchTarget} />
              <Button onClick={getMedia}>Get Media</Button>
            </form>
          </Row>
        </Container>
        {items.map((asset, indexKey) => (
          <Asset type={asset.type} href={asset.url} key={indexKey} />
        ))}
      </React.Fragment>
    </Template>
  );
};

export default withRouter(About);
